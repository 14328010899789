import React from "react";
import { Route, Routes } from 'react-router-dom';


import "../Home.css";
import "./Wcard.css";

import About from "./About";

import WHome from "./WHome";

const Wcard =()=>{


    return(
        <>
          {/* <WHeader/> */}
         <Routes>
          <Route path="/" element={<WHome/>}/>
          <Route path="/about" element={<About/>}/>
         </Routes>
        

        </>
    )
}

export default Wcard;