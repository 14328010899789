import React, {useState} from "react";

import { useNavigate } from "react-router-dom";


const LogIn = () => {
 
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleLogin = () => {
    if (email) {
      
      navigate('/card', { state: { userEmail: email } });
    } else {
      alert('Please enter a valid email');
    }
  };

  return (
    <>
      <body class="bodybg">
        <div class="container py-5 mb-3">
          
          <div class="row">
            <div class="col-md-12">
              <div class="font2 text-md-center">Welcome back,</div>
              <div class="font1 text-md-center">
                Glad to meet you again! please login to use the app.
              </div>
            </div>
          </div>

          <div class="row mt-5  justify-content-center">
            <div class="col-12  my-2 col-md-6">
              <input
                type="email"
                class="form-control border-0 "
                placeholder="Email"
                aria-label="Email"
                value={email}
        onChange={handleChange}
              />
            </div>
            </div>
            <div className="row justify-content-center">
            <div class="col-12 col-md-6  my-2">
              <input
                type="text"
                class="form-control border-0"
                placeholder="Phone Number"
                aria-label="Phone Number"
              />
            </div>
            </div>
           <div className="row justify-content-center ">
           <div class="col-12 col-md-6 my-2">
              <input
                type="password"
                class="form-control border-0"
                placeholder="Password"
                aria-label="Password"
              />
            </div>
           </div>
         
          <div class="row ">
            <div class="col-12 col-md-9 text-end">
              <div class="font3 textclr">Forget Password?</div>
            </div>
          </div>

          <div class="row my-5 justify-content-center">
            <div class="col-12 col-md-6 text-center">
              <button
                type="button"
                class="borderrad py-2 h-100  w-100 px-md-5 text-white fw-bold btnbg"
                onClick={handleLogin}
              >
                Sign In
              </button>
            </div>
          </div>

          <div class="row mt-5 justify-content-center">
            <div class="col-sm-12 col-md-6">
              <button
                type="button"
                class="borderrad py-2 px-md-5 w-100 textclr"
              >
                <i class="fa-brands fa-google "></i> Sign In with Google
              </button>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-sm-12 text-center">
              <p>
                <span>Dont have an account?</span>{" "}
                <span class="textclr"> Join Now</span>{" "}
              </p>
            </div>
          </div>
        </div>

        
      </body>
      
    </>
  );
};

export default LogIn;
