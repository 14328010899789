import "../Home.css";
import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className=" bg-white py-2 ">
          <div className="footer-nav px-2">

            <NavLink to="/home" className="footer-icon border-0 " activeClassName="active">
              <div className="iconfont">
                <i class="fa-solid fa-house"></i>
              </div>
              <div>Home</div>
            </NavLink>
            <NavLink to="/categories" className="border-0 footer-icon  ">
              <div className="iconfont">
                <i class="fa-regular fa-clock"></i>
              </div>
              <div>Categories</div>
            </NavLink>
            <NavLink to="/cart" className="border-0 footer-icon bg-white">
              <div className="iconfont">
                <i class="fa-solid  fa-cart-shopping"></i>
              </div>
              <div>Cart</div>
            </NavLink>
            <NavLink to="/offers"  className="border-0 footer-icon bg-white">
              <div className="iconfont">
                <i class="fa-solid fa-percent"></i>
              </div>
              <div>Offers</div>
            </NavLink>
            <NavLink to="/card"  className="border-0 footer-icon bg-white">
              <div className="iconfont">
                <i class="fa-solid fa-credit-card"></i>
              </div>
              <div>Card</div>
            </NavLink>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
