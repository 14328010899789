import React from "react";

import "../src/Terms.css";

const TermsAndConditions = () => {
  return (
    <div className="container py-5 ">
      <h2 className="text-center mb-4 aboutcontent">Terms and Conditions</h2>
      <h5 className="aboutcontent pb-3">
        Welcome to VIPCards! By using our services, you agree to the following terms and conditions.
      </h5>

      <h5>1. Acceptance of Terms</h5>
      <p>
        By accessing or using our website, you agree to be bound by these terms and our privacy policy. If you do not agree 
        with any part of these terms, you must discontinue the use of our services immediately.
      </p>

      <h5>2. Use of Services</h5>
      <p>
        VIPCards provides tools to create digital business cards. You must not use our platform for any unlawful activities, 
        including but not limited to fraud, misrepresentation, or sharing offensive content.
      </p>

      <h5>3. Intellectual Property</h5>
      <p>
        All content, including text, graphics, logos, and software, is the property of VIPCards and is protected by copyright 
        laws. Unauthorized use of any of our intellectual property is strictly prohibited.
      </p>

      <h5>4. User Content</h5>
      <p>
        You retain ownership of the content you create. However, by using our platform, you grant us a non-exclusive, 
        worldwide, royalty-free license to use, display, and distribute your content solely for the purpose of delivering 
        our services.
      </p>

      <h5>5. Account Responsibility</h5>
      <p>
        You are responsible for maintaining the confidentiality of your account information. You must notify us immediately 
        of any unauthorized access or use of your account. VIPCards is not liable for any losses resulting from your failure 
        to secure your account credentials.
      </p>

      <h5>6. Payment Terms</h5>
      <p>
        If you subscribe to any paid plans or services, you agree to pay all applicable fees as specified. All payments are 
        non-refundable unless stated otherwise. Failure to complete payments may result in service suspension or termination.
      </p>

      <h5>7. Limitation of Liability</h5>
      <p>
        VIPCards is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or 
        inability to use our services, including but not limited to data loss or system downtime.
      </p>

      <h5>8. User Conduct</h5>
      <p>
        You agree to use VIPCards in a manner that complies with all applicable laws and regulations. You are prohibited from 
        uploading, sharing, or distributing any content that is harmful, abusive, or violates the rights of others.
      </p>

      <h5>9. Termination of Services</h5>
      <p>
        We reserve the right to suspend or terminate your access to our services at any time, without prior notice, for 
        violating these terms or engaging in any activities deemed harmful to VIPCards or its users.
      </p>

      <h5>10. Changes to Terms</h5>
      <p>
        VIPCards reserves the right to update these terms at any time. We encourage you to review this page periodically. 
        Continued use of the platform after changes are made constitutes acceptance of the revised terms.
      </p>

      <h5>11. Governing Law</h5>
      <p>
        These terms are governed by and construed in accordance with the laws of [your jurisdiction]. Any disputes arising 
        from these terms will be subject to the exclusive jurisdiction of the courts in [your jurisdiction].
      </p>

      <h5>12. Third-Party Links</h5>
      <p>
        Our website may contain links to third-party websites or services. We are not responsible for the content, policies, 
        or practices of these third-party sites. Accessing such sites is at your own risk.
      </p>

      <h5>13. Contact Us</h5>
      <p>
        If you have any questions, concerns, or suggestions regarding these terms, please contact us at 
        <a href="mailto:support@vipcards.com" className="text-decoration-none"> support@vipcards.com</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
