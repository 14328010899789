import React, {useState, useEffect} from "react";
import "../Home.css";
import waxing from "./images/Makeup.png"
import aroma from "./images/Aroma.png"
import spa from "./images/Spa Mask.png"
import massage from "./images/Beautician.png"
import hair from "./images/Hairdresser.png"
import logoimg from "./images/google logo 1.png"
import { QRCodeSVG } from 'qrcode.react';
import { useLocation } from "react-router-dom";


const BusinessCard =()=>{

  const location = useLocation(); 
  const userEmail = location.state?.userEmail;
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    if (userEmail) {
      setShowQRCode(true); // Show the QR code if email is passed
    } else {
      setShowQRCode(false); // Don't show the QR code if no email
    }
  }, [userEmail]); 


    return(
        <>
<div className="containier-fluid overflow-hidden bg-white">
        <div className="d-flex bg-white px-3 mt-5 py-5 ">
          <div>
            <h3 className="mb-0">Business Card</h3>
            <div className="font1">Share your work and connect with all</div>
          </div>
          <p className="ms-auto ">
          <i class="fa-solid iconbg2 p-1 fa-xmark"></i>
          </p>
        </div>

        

       <div className="cardbg">
       <div className="row pt-5 pb-4">
          <div className="col-4 text-end pt-2">
            <img
              className="salon-logo3 "
              src="https://images.pexels.com/photos/27914301/pexels-photo-27914301/free-photo-of-reflexion-urbana.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt="profile"
            />
          </div>
          <div className="col-8">
            <div className="headerfont text-center">
              <div className="martop">Vaishnavi </div>
              <div className="martop"> Beauty Salon</div>
            
            </div>
            <div className="text-center">LOCATED IN KHARADI</div>
          </div>
          
        </div>

        <div className="row pb-2 text-center">
        <h1 class="two-color-text">Services we provide</h1>
        </div>

      <div>
      <div className="row pipebg2 mx-3 my-2 py-1 my-md-4 mx-md-auto py-md-2 productwd servicefont2">
        <div className="col-3 col-md-6 text-end text-md-center">
        <img src={waxing} className="img-fluid" alt="makeup"/>
        </div>
        <div className="col-9 col-md-6 my-auto">
            <div>Waxing/Threading</div>
        </div>
        </div>

        <div className="row pipebg2 mx-3 my-2 py-1 my-md-4 mx-md-auto py-md-2 productwd servicefont2">
        <div className="col-3 col-md-6 text-end text-md-center">
        <img src={aroma} className="img-fluid" alt="makeup"/>
        </div>
        <div className="col-9 col-md-6 my-auto">
            <div>Manicure - Pedicure</div>
        </div>
        </div>

        <div className="row pipebg2 mx-3 my-2 py-1 my-md-4 mx-md-auto py-md-2 productwd servicefont2">
        <div className="col-3 col-md-6 text-end text-md-center">
        <img src={spa} className="img-fluid" alt="makeup"/>
        </div>
        <div className="col-9 col-md-6 my-auto">
            <div>Facial Services</div>
        </div>
        </div>

        <div className="row pipebg2 mx-3 my-2 py-1 my-md-4 mx-md-auto py-md-2 productwd servicefont2">
        <div className="col-3 col-md-6 text-end text-md-center">
        <img src={massage} className="img-fluid" alt="makeup"/>
        </div>
        <div className="col-9 col-md-6 my-auto">
            <div>Massage Therapy</div>
        </div>
        </div>

        <div className="row pipebg2 mx-3 my-2 py-1 my-md-4 mx-md-auto py-md-2 productwd servicefont2">
        <div className="col-3 col-md-6 text-end text-md-center">
        <img src={hair} className="img-fluid" alt="makeup"/>
        </div>
        <div className="col-9 col-md-6 my-auto">
            <div>Hairstyle</div>
        </div>
        </div>
      </div>

        <div>
            <div className="contactbg mx-md-auto p-md-4 row justify-content-start my-3 mx-2">
                <h3>Contact us</h3>
                <div className="row pt-2">
                    <div className="col-9 col-md-8">
                        <div className="font1 text-white"><i class="fa-solid fa-phone"></i> <span className="ps-2">(+91) 94720-5769</span></div>
                        <div className="font1 py-2 text-white"><i class="fa-regular fa-envelope"></i> <span className="ps-2">prii.mfurlani@gmail.com</span></div>
                        <div className="font1 text-white"><i class="fa-solid fa-globe"></i> <span className="ps-2">http://sample.org/</span></div>
                    </div>
                    {showQRCode && ( // Conditionally render this section if showQRCode is true
                  <div className="col-3 col-md-4">
                    <div>
                      <img
                        className="bg-white p-1 rounded-circle logoimg"
                        src={logoimg}
                        alt="logo"
                      />
                    </div>
                    <div className="qrimg rounded-2 ">
                      <QRCodeSVG value={userEmail} className="img-fluid" />
                    </div>
                  </div>
                )}
                </div>
            </div>
        </div>

        <div className="row pb-4">
            <div className="col text-center">
                
<button className="btn cardbtn px-4 py-2  rounded-pill">SHARE NOW</button>
            </div>
        </div>

       </div>




        </div>
        </>
    )
}

export default BusinessCard;