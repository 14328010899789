import React, {useState} from "react";
import "./LoginForm.css";
import axios from "axios";
// import Category from './Category'

const LoginForm =()=>{

    const [footerTextColor, setFooterText] = useState('#000000');
    const [footerBackColor, setFooterBack] = useState('#ffffff');
    const [headerTextColor, setHeaderText] = useState('#000000');
    const [headerBackColor, setHeaderBack] = useState('#ffffff');

    const [profileImage, setProfileImage] = useState("");
    const [logoImage, setLogoImage] = useState("");
    const [currentStep, setCurrentStep] = useState(1);


    const handleImageChange =(event)=>{
        const file = event.target.files[0]
        if(file){
            const reader = new FileReader();
            reader.onload = ()=>{
                setProfileImage(reader.result);
                localStorage.setItem("profileImage", reader.result);
            }
            reader.readAsDataURL(file);
        }
    }



    const handleLogoChange = (event) =>{
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload =(e) =>{
            setLogoImage(e.target.result);
        };
        reader.readAsDataURL(file);
    }



    const nextStep = () => {
        if (currentStep < 4) {
          setCurrentStep((prevStep) => prevStep + 1);
        }
      };


    return(
        <>
        <div className="container-fluid profileform">
            <div className="form">
               
                 <form>
                   
                    <div className="row flex text-center pt-5">
  <div className="flex  ">
    <button
      onClick={() => setCurrentStep(1)}
      className={` mx-4 border-0 ${currentStep === 1 ? 'text-info' : 'text-dark'}`}
    >
      Profile
    </button>
    <button
      onClick={() => setCurrentStep(2)}
      className={`mx-4 border-0 ${currentStep === 2 ? 'text-info' : 'text-dark'}`}
    >
      Category
    </button>
    <button
      onClick={() => setCurrentStep(3)}
      className={`mx-4 border-0  ${currentStep === 3 ? 'text-info' : 'text-dark'}`}
    >
      Business Details
    </button>
    <button
      onClick={() => setCurrentStep(4)}
      className={`mx-4 border-0 ${currentStep === 4 ? 'text-info' : 'text-dark'}`}
    >
      Header Footer
    </button>
  </div>
</div>

                 <div className="row pb-4  ">
                     <div className="col-12  mt-md-5">
                        {currentStep===1 &&(
                             <div  className="row container pb-5 mb-5 bg-white shadow  mx-auto">
                             <div className="row py-3">
                                 <h2 className="col-12 text-center">Profile</h2>
                             </div>
                            
                             
                             <div className="col-md-2 text-md-end"> 
                             <i class="fa-solid fa-pencil custompen"></i>
                             <img  src={profileImage || "https://via.placeholder.com/150?text=No+Image"} alt="profile" className="rounded-circle border-2 profileimg" onClick={()=>document.getElementById('profileInput').click()}/>
                             </div>
 
                             
                             <div className="col-md-4">
                             <div className="row my-3 justify-content-center">
                            <div className="col-12">
                             <div className="row justify-content-center">
                                 <div className="col-md-4 text-md-end"><label htmlFor="name">Name:</label></div>
                                 <div className="col-md-6"><input className="pe-5" type="text"
                                 placeholder="Enter your name"/></div>
                             </div>
                            </div>
                            </div>
                            <div className="row my-3 justify-content-center">
                            <div className="col-12">
                             <div className="row justify-content-center">
                                 <div className="col-md-4 text-md-end"><label htmlFor="mobile">Mobile:</label></div>
                                 <div className="col-md-6"><input className="pe-5"  type="text" placeholder="Enter your number"/></div>
                             </div> 
                            </div>
                            </div>
                             </div>
 
 
                             <div className="col-md-4">
                             <div className="row my-md-3 justify-content-center">
                            <div className="col-12">
                             <div className="row justify-content-center">
                                 <div className="col-md-4 text-md-end"><label htmlFor="email">Email:</label></div>
                                 <div className="col-md-6"><input className="pe-5"  type="email" placeholder="Enter your email"/></div>
                             </div>
                            </div>
                            </div>
                            <div className="row my-3 justify-content-center">
                            <div className="col-12">
                             <div className="row justify-content-center">
                                 <div className="col-md-4 text-md-end"><label htmlFor="password">Password:</label></div>
                                 <div className="col-md-6"><input className="pe-5"  type="password" placeholder="Enter your password"/></div>
                             </div>
                            </div>
                            </div>
                             </div>
 
             <div className="row mx-auto pt-md-3">
                     <div className="col-md-12 mx-auto ">
                         <div className="row justify-content-center">
                         
                         <div className="col-md-7 my-auto">
                        <div className="row py-2">   
                      
                         <div className="col-md-6">
                             <input class="form-control" accept="image/*" onChange={handleImageChange} style={{display: "none"}}  type="file" id="profileInput"/>
                             </div>
                        </div>
 
                        <div className="row py-2">
                       
                         <div className="col-md-6 mx-auto">
                         <input className="form-control btn btn-outline-dark" value="Next" type="submit"/>
                             </div>
                        </div>
                         </div>
                  </div>
                 
                       
                  </div>
              </div>
             
                         </div>
                        )}
 
 
 {currentStep===2 &&(
    
 <div className="row container pb-5 mb-5 bg-white shadow mx-auto">
 <div className="row py-3">
     <h2 className="col-12 text-center">Category</h2>
 </div>

 <div className="row pt-md-3">
 <div className="col-md-6 mx-auto ">
<div >
<select className="form-select mt-2 mt-md-0" aria-label="Default select example">
<option selected>Select Business Type</option>
<option value="1">One</option>
<option value="2">Two</option>
<option value="3">Three</option>
</select>
</div>
</div>
</div>

<div className="row pt-md-3">
 <div className="col-md-6 mx-auto ">
<div >
<select className="form-select mt-2 mt-md-0" aria-label="Default select example">
<option selected>Select Category</option>
<option value="1">One</option>
<option value="2">Two</option>
<option value="3">Three</option>
</select>
</div>
</div>
</div>

<div className="row pt-md-3">
 <div className="col-md-6 mx-auto ">
<div >
<select className="form-select my-2 my-md-0" aria-label="Default select example">
<option selected>Select Sub Category</option>
<option value="1">One</option>
<option value="2">Two</option>
<option value="3">Three</option>
</select>
</div>
</div>
</div>

<div className="row pt-md-3">
 <div className="col-md-12 mx-auto ">
<div className="row">

<div className="col-md-4 mx-auto">
 <input className="form-control btn btn-outline-dark" value="Next" type="submit" id="formFile1"/>
 </div>
</div>
</div>
</div>

</div>
 )}
                            
                         
                        {currentStep ===3 &&(
                             <div className="row container pb-5 mb-5 bg-white shadow mx-auto">
                             <div className="row py-3">
                                 <h2 className="col-12 text-center">Business Details</h2>
                             </div>
 
 
 
 
                             <div className="col-md-3 text-md-end"> 
                             <i class="fa-solid fa-pencil custompen"></i>
                             <img  src={logoImage || "https://via.placeholder.com/150?text=No+Image"} alt="profile" className="rounded-circle border-2 profileimg" onClick={()=>document.getElementById('logoInput').click()}/>
                             
                             </div>
                            
                             
                             <div className="col-md-8">
                             <div className="row my-3 justify-content-center">
                            <div className="col-12">
                             <div className="row justify-content-center">
                             <div className="col-md-4 text-md-end"><label htmlFor="name">Business Name:</label></div>
                             <div className="col-md-6 pe-0"><input  className="w-100" type="text" placeholder="Enter your business name"/></div>
                             </div>
                            </div>
                            </div>
                            <div className="row my-3 justify-content-center">
                            <div className="col-12">
                             <div className="row justify-content-center">
                             <div className="col-md-4 text-md-end"><label htmlFor="name">Business Contact:</label></div>
                             <div className="col-md-6 pe-0"><input className="w-100" type="text" placeholder="Enter your business number"/></div>
                             </div> 
                            </div>
                            </div>
                             </div>
 
 
 
                             <div className="row">
                                 <div className="col-md-12">
                                 <div className="row py-md-2">
                                     <div className="col-md-3 text-md-end pe-md-4 my-md-auto">
                                         <label htmlFor="address">Business Address:</label>
                                     </div>
                                  
                                     <div className="col-md-8">
                                         <input className="form-control w-100" type="text" placeholder="Enter Business Address"/>
                                     </div>
                                 </div>
                                 </div>
                             </div>
 
                             <div className="row my-md-3 justify-content-center">
                            <div className="col-md-5">
                            <select className="form-select my-3 my-md-0"  aria-label="Default select example">
                         <option selected>Select District</option>
                         <option value="1">One</option>
                         <option value="2">Two</option>
                         <option value="3">Three</option>
                         </select>
                            </div>
                            <div className="col-md-5">
                            <select className="form-select " aria-label="Default select example">
                         <option selected>Select Tehsile</option>
                         <option value="1">One</option>
                         <option value="2">Two</option>
                         <option value="3">Three</option>
                         </select>
                            </div>
                            </div>
 
                            
                            <div className="row my-2 justify-content-center">
                            <div className="col-md-5 my-2 my-md-0">
                            <select className="form-select " aria-label="Default select example">
                         <option selected>Select Village/City</option>
                         <option value="1">One</option>
                         <option value="2">Two</option>
                         <option value="3">Three</option>
                         </select>
                            </div>
                            <div className="col-md-5">
                           <div className="row">
                             <div className="col-5 col-md-4 text-md-end my-auto">
                                 <label htmlFor="pincode">Pin code:</label>
                             </div>
                             <div className="col-7 col-md-6">
                                 <input type="text" className="form-control"/>
                             </div>
                           </div>
                            </div>
                            </div>
 
                            <div className="row mx-auto pt-md-3">
                     <div className="col-md-12 mx-auto ">
                         <div className="row justify-content-center">
                        
                         <div className="col-md-7 my-auto">
                        <div className="row py-2">
                       
                         <div className="col-md-12">
                             <input class="form-control" accept="image/*" onChange={handleLogoChange} type="file" id="logoInput" style={{display: "none"}}/>
                         </div>
                     
                         <div className="col-md-3 pt-md-3  my-auto">
                             <label htmlForfor="formFile2" className="form-label ">Shop Images:</label>
                         </div>
                         <div className="col-md-7 pt-md-3">
                             <input class="form-control" type="file" id="formFile2" multiple/>
                         </div>
                        </div>
 
                         </div>
                  </div>
                  </div>
                            </div>
 
                             <div className="row pt-md-3">
                             <div className="col-md-12 mx-auto ">
                         <div className="row">
                         <div className="col-md-4 my-auto text-md-end">
                             <label htmlForfor="formFile3" className="form-label ">GST Number:</label>
                             </div>
                         <div className="col-md-5">
                             <input className="form-control"   type="text" id="formFile3"/>
                             </div>
                         </div>
                     </div>
                     </div>
                             
                             <div className="row pt-md-3">
                             <div className="col-md-12 mx-auto ">
                         <div className="row mt-2 mt-md-0">
                         {/* <div className="col-md-4 my-auto text-md-end"><label htmlForfor="submit" className="form-label ">Submit :</label></div> */}
                         <div className="col-md-5 mx-auto"><input className="form-control btn btn-outline-dark" type="submit" value="Next" id="formFile4"/></div>
                         </div>
                     </div>
                     </div>
 
                      
                         </div> 
                        )}
 
                        {currentStep===4 && (
                             <div className="row container pb-5 mb-5 bg-white shadow   mx-auto">
                             <div className="row py-3">
                                 <h2 className="col-12 text-center">Header Footer</h2>
                             </div>
 
                             
                             <div className="col-md-12">
                             <div className="row my-3 justify-content-center">
                            <div className="col-md-6">
                             <div className="row justify-content-center">
                                 <div className="col-md-5 text-md-end"><label htmlFor="name">Header Text Color:</label></div>
                                 <div className="col-md-4 pe-0">
                                     <input className="w-100" type="color" placeholder="Enter your business name" value={headerTextColor} onChange={(e)=>setHeaderText(e.target.value)} style={{cursor: 'pointer'}}/>
                                 </div>
                                 <div className="col-md-2">{headerTextColor}</div>
                             </div>
                            </div>
                            <div className="col-md-6">
                             <div className="row justify-content-center">
                                 <div className="col-md-5 text-md-end"><label htmlFor="name">Header Background Color:</label></div>
                                 <div className="col-md-4 pe-0">
                                     <input className="w-100" type="color" placeholder="Enter your business number"
                                      value={headerBackColor} onChange={(e)=>setHeaderBack(e.target.value)} style={{cursor: 'pointer'}}/>
                                     </div>
                                     <div className="col-md-2">{headerBackColor}</div>
                             </div>
                            </div>
                            </div>
                           
                             </div>
 
                               
                             <div className="col-md-12">
                             <div className="row my-3 justify-content-center">
                            <div className="col-md-6">
                             <div className="row justify-content-center">
                                 <div className="col-md-5 text-md-end"><label htmlFor="name">Footer Text Color:</label></div>
                                 <div className="col-md-4 pe-0">
                                     <input className="w-100" type="color" placeholder="Enter your business name" 
                                     value={footerTextColor} onChange={(e)=>setFooterText(e.target.value)} style={{cursor: 'pointer'}}/>
                                     
                                     </div>
                                     <div className="col-md-2">{footerTextColor}</div>
                             </div>
                            </div>
                            <div className="col-md-6">
                             <div className="row justify-content-center">
                                 <div className="col-md-5 text-md-end"><label htmlFor="name">Footer Background Color:</label></div>
                                 <div className="col-md-4 pe-0">
                                     <input className="w-100" type="color" placeholder="Enter your business number"
                                      value={footerBackColor} onChange={(e)=>setFooterBack(e.target.value)} style={{cursor: 'pointer'}}/>
                                 </div>
                                 <div className="col-md-2">{footerBackColor}</div>
                             </div>
                            </div>
                            </div>
                           
                             </div>
                             <div className="row my-3">
                             <div className="col-md-4 mx-auto"><input className="form-control btn btn-outline-dark" type="submit" id="formFile5"/></div>
                             </div>
 
                         </div>
                        )}
 
                   
                 </div>
                
                </div>

                <div className="flex justify-content-between mt-5">
           
            <div className="flex space-x-4">
              {currentStep !== 4 && (
                <button
                  type="button"
                  onClick={nextStep}
                  className="text-dark"
                >
                  Next
                </button>
              )}
              {currentStep === 4 && (
                <button
                  type="submit"
                  className="text-sm font-medium text-teal-600"
                >
                  Submit
                </button>
              )}
            </div>
          </div>

                 </form>
               
            
            </div>
        </div>
        </>
    )
}

export default LoginForm;