import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../Home.css";
import "./Header.css";  // Ensure your CSS is imported

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu visibility

  const handleLogoClick = () => {
    setMenuOpen(!menuOpen); // Toggle menu visibility when profile image is clicked
  };



  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setIsDarkMode(true); // Set dark mode if saved in localStorage
      document.body.classList.add("dark-mode"); // Apply dark mode to body
    } else {
      document.body.classList.remove("dark-mode"); // Apply light mode
    }
  }, []);

  // Handle the theme toggle
  const handleThemeToggle = () => {
    setIsDarkMode(prevMode => {
      const newMode = !prevMode;
      // Save the theme preference to localStorage
      localStorage.setItem("theme", newMode ? "dark" : "light");
      // Apply or remove dark mode class to the body
      if (newMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
      return newMode;
    });
  };




  return (
    <header className="header headerbg py-2">
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          {/* Brand Logo */}
          <div className="row justify-content-center w-100">
            <div className="col-auto col-md-3 text-center">
              <img
                className="salon-logo"
                src="https://images.pexels.com/photos/27914301/pexels-photo-27914301/free-photo-of-reflexion-urbana.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                onClick={handleLogoClick} // Toggle menu on logo click
                style={{ cursor: "pointer" }} // Pointer cursor indicating clickable logo
              />
            </div>
            <div className="col col-md-7 text-md-center mt-md-0">
              <div className="header-details">
                <h2 className="d-none d-md-block">Vaishnavi Beauty Salon</h2>
                <h6 className="d-block font7 d-md-none">Vaishnavi Beauty Salon</h6>
                <p className="mb-0 px-md-2 px-start font3">
                  <i className="fa-solid fa-location-arrow"></i> 52WQ+2X5, New South Wales
                </p>
              </div>
            </div>
            <div className="col-auto col-md-2 my-auto text-md-center">
              <h5>
                <i className="fa-solid fa-bell bell"></i>
              </h5>
            </div>
          </div>

          {/* Collapsible Content for Mobile */}
          <div className={`navbar-menu ${menuOpen ? "show" : ""}`} style={{ display: menuOpen ? "block" : "none" }}>
            <ul className="navbar-nav mx-auto">
              <li className="nav-item pt-5 mt-5 pt-md-0 mt-md-0">
                <NavLink to="/home" className="nav-link pt-2  mt-5 mt-md-0" activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/categories" className="nav-link">
                  Categories
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/cart" className="nav-link">
                  Cart
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/offers" className="nav-link">
                  Offers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/profile" className="nav-link">
                  Profile
                </NavLink>
              </li>
              <i className="fa-regular icontheme my-auto fa-sun me-2"></i>
          <div className="form-check my-auto form-switch">
            <input 
              className="form-check-input" 
              type="checkbox" 
              role="switch" 
              checked={isDarkMode} 
              onChange={handleThemeToggle} 
            />
          </div>
          <i className="fa-regular icontheme my-auto fa-moon"></i>
            </ul>




         



          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
