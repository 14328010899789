import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "../Home.css";
import "./Wcard.css";
import slider1 from "./images/slider1.jpg";
import slider3 from "./images/slider3.gif";
import { Link } from "react-router-dom";

const WHome=()=>{

    
  
    const [plan, setPlan] = useState('monthly');

    const PricingData={
        silver: {monthly: `${String.fromCharCode(8377)}199`, annual: `${String.fromCharCode(8377)}999` },
        golden: {monthly: `${String.fromCharCode(8377)}299`, annual: `${String.fromCharCode(8377)}1999`},
        platinum: {monthly: `${String.fromCharCode(8377)}499`, annual: `${String.fromCharCode(8377)}2999`}
    }


    return(
        <>
        <div className="container-fluid p-0 overflow-hidden" >

<div className="bgimg">
    <div className="container pt-5">
    <div
      id="carouselExample"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {/* Slide 1 */}
        <div className="carousel-item active">
          <div className="row pt-3 justify-content-center">
            <div className="col-md-6 my-auto">
              <h1 className="fw-normal">Create . Share . Connect</h1>
              <h1>Digital Business Card</h1>
              <p className="py-2 wfont1 fw-normal text-secondary">
                A better way to represent yourself & get more business connects.
              </p>
              <h5>Trusted by 20k+</h5>
              <div className="row">
                <div className="col-sm-12 col-md-6 mt-5">
                  <button className="text-white wcardbtn border-0 py-2 px-3 rounded-2">
                    Create Your Own Card
                  </button>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-md-12">
                  <p className="text-secondary">
                    The #1 digital business card platform.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <img
                className="w-100 h-100"
                src={slider1}
                alt="card"
              />
            </div>
          </div>
        </div>

        {/* Slide 2 (Same Content) */}
        <div className="carousel-item">
          <div className="row pt-3 justify-content-center">
           
            <div className="col-md-6">
              <img
                className="w-100 h-100"
                src={slider3}
                alt="card"
              />
            </div>

            <div className="col-md-6 my-auto">
              <h1 className="fw-normal">Create . Share . Connect</h1>
              <h1>Digital Business Card</h1>
              <p className="py-2 wfont1 fw-normal text-secondary">
                A better way to represent yourself & get more business connects.
              </p>
              <h5>Trusted by 20k+</h5>
              <div className="row">
                <div className="col-sm-12 col-md-6 mt-5">
                  <button className="text-white wcardbtn border-0 py-2 px-3 rounded-2">
                    Create Your Own Card
                  </button>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-md-12">
                  <p className="text-secondary">
                    The #1 digital business card platform.
                  </p>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>

      {/* Carousel Controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExample"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    </div>
</div>



<div className="container">
<div className="row mt-5">
    <div className="col-md-6 pt-3">
        <h2>Most trusted and <br/> reviewed digital business <br/> card platform.</h2>
    </div>
    <div className="col-md-6">
        <div className="row ">
        <div className="d-md-flex d-none ">
        <div className="contentbg text-center px-5 py-1 rounded-4">
            <h1>100K+</h1>
            <p className="wfont1">Successful Users</p>
        </div>
        <div className="contentbg ms-auto text-center px-5 py-1 rounded-4">
            <h1>18M+</h1>
            <p className="wfont1">Views on cards</p>
        </div>
        </div>
    </div>
    <div className="row mt-3">
        <div className="d-md-flex d-none">
        <div className="contentbg text-center px-5 py-1 rounded-4">
            <h1>10K+</h1>
            <p className="wfont1">Contact exchanged</p>
        </div>
        <div className="contentbg ms-auto text-center px-5 py-1 rounded-4">
            <h1>20K+</h1>
            <p className="wfont1">Leads generated</p>
        </div>
        </div>
    </div>
</div>
</div>

<div className="row py-5 text-center">
    <h2>How wCard.io works?</h2>
</div>
<div className="row ">
    <div className="col-md-6 me-md-5">
        <div className="bg-success carddimension rounded-4 px-3 mx-md-5 pb-4">
            <div >
            <img className="img-fluid wcardimg " src="https://images.pexels.com/photos/1662159/pexels-photo-1662159.jpeg?auto=compress&cs=tinysrgb&w=600" alt="business"/>
            </div>
        </div>
    </div>
    <div className="col-md-5">
        <h5>01</h5>
        <h2 className="fw-bold py-2">Create Digital Business <br/> Card Online</h2>
        <p className="wfont1">Make digital business card and make it more informative by adding the following items:</p>
        <ul className="list-unstyled ps-3 text-secondary">
            <li>Main Action & Whatsapp Button</li>
            <li>Payment & Social Profile Links</li>
            <li>Photo, Banners, Portfolio, Videos, Podcast, etc</li>
            <li>PDF Attachments & more...</li>
        </ul>

        <div className="pt-5">
            <button className="btn btn-outline-success py-2 px-3 rounded-pill">Create Your Own Card</button>
        </div>
    </div>
</div>



<div className="row py-5">
    <div className="col-md-6 me-md-5">
        <div className=" carddimension rounded-4 pb-4">
            <div >
            <img className="img-fluid wcardimg " src="https://wcardcdn.b-cdn.net/assets/img/web/features-pages-and-sections.png" alt="business"/>
            </div>
        </div>
    </div>
    <div className="col-md-5 wcardbg1 p-3 rounded-4">
      
        <h2 className="fw-bold py-2">Multiple Pages and <br/> Sections</h2>
        <p className="wfont1">On your digital business card, you have the flexibility to include various pages and sections.</p>
        <ul className="list-unstyled ps-3 text-secondary">
            <li>Main Action & Whatsapp Button</li>
            <li>Payment & Social Profile Links</li>
            <li>Photo, Banners, Portfolio, Videos, Podcast, etc</li>
            <li>PDF Attachments & more...</li>
        </ul>

        <div className="pt-3 pb-2">
            <button className="btn btn-outline-success py-3 px-3 rounded-pill">Create Your Own Card</button>
        </div>
    </div>
</div>




         </div>
         

         <div className="row py-5 bgimg">
            <div className="container">
                <h1 className="text-center fw-bold py-4">Pricing Plans</h1>
                <div className=" d-flex justify-content-center  py-4">
         <div class="form-check ">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={plan === 'monthly'} onChange={()=> setPlan('monthly')}/>
  <label class="form-check-label  pe-4" for="flexRadioDefault1">
    Monthly
  </label>
</div>
<div class="form-check">
  <input class="form-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={plan === 'annual'} onChange={()=> setPlan('annual')} />
  <label class="form-check-label  " for="flexRadioDefault2">
    Annual
  </label>
</div>
         </div>
                <div className="row justify-content-center container mx-auto">
                    <div className="col pricesection p-5 mx-2 rounded-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 pt-2">
                                    <img className="bg-info p-2 rounded-3" src="https://wcardcdn.b-cdn.net/assets/img/landing/saas-1/pricing/starter.svg" alt="svgimg"/>
                                    </div>
                                    <div className="col-8">
                                    <h4 className="mb-0">Silver</h4>
                                    <span className="h2 mb-0">{PricingData.silver[plan]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 wfont2 text-secondary">
                            1 User
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-12 ">
                            <p>For individuals with basic customizations.  </p> 
                            </div>
                        </div>
                        <div className="row my-2 ">
                            <div className="col-12 ">
                            <button className="btn px-4 py-1 btn-outline-success" type="submit">
              Signup for Free
            </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 py-2">
                                <h5>No Credit Card Required!</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ">
                               <ul className=" ms-3" style={{listStyle: "square"}}>
                                <li className="wfont2 py-1">Digital Business Card for yourself</li>
                                <li className="wfont2 py-1">Basic customizations</li>
                                <li className="wfont2 py-1">wCard Branding</li>
                                <li className="wfont2 py-1">Include a profile image</li>
                                <li className="wfont2 py-1">Include a cover image</li>
                                <li className="wfont2 py-1">Add Education and Experience Tabs</li>
                                <li className="wfont2 py-1">Add Contact Form</li>
                                

                               </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col pricesection border border-success p-5 mx-2 rounded-5">
                    <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 pt-2">
                                    <img className="bg-info p-2 rounded-3" src="https://wcardcdn.b-cdn.net/assets/img/landing/saas-1/pricing/starter.svg" alt="svgimg"/>
                                    </div>
                                    <div className="col-8">
                                    <h4 className="mb-0">Golden</h4>
                                    <span className="h3 mb-0">{PricingData.golden[plan]} <small className="h6 text-secondary">{plan==='monthly' ? 'per month' : 'per year'}</small></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 wfont2 text-secondary">
                            1 User
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-12 ">
                            <p>For individuals with basic customizations.  </p> 
                            </div>
                        </div>
                        <div className="row my-2 ">
                            <div className="col-12 ">
                            <button className="btn px-4 py-1 btn-outline-success" type="submit">
              Signup for Free
            </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 py-2">
                                <h5>No Credit Card Required!</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ">
                               <ul className=" ms-3" style={{listStyle: "square"}}> 
                                <li className="wfont2 py-1">Digital Business Card for yourself</li>
                                <li className="wfont2 py-1">Basic customizations</li>
                                <li className="wfont2 py-1">wCard Branding</li>
                                <li className="wfont2 py-1">Include a profile image</li>
                                <li className="wfont2 py-1">Include a cover image</li>
                                <li className="wfont2 py-1">Add Education and Experience Tabs</li>
                                <li className="wfont2 py-1">Add Contact Form</li>
                                

                               </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col pricesection  p-5 mx-2  rounded-5">
                    <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-4 pt-2">
                                    <img className="bg-info p-2 rounded-3" src="https://wcardcdn.b-cdn.net/assets/img/landing/saas-1/pricing/starter.svg" alt="svgimg"/>
                                    </div>
                                    <div className="col-8">
                                    <h4 className="mb-0">Platinum</h4>
                                    <span className="h3 mb-0">{PricingData.platinum[plan]} <small className="h6 text-secondary">{plan==='monthly' ? 'per month' : 'per year'}</small></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 wfont2 text-secondary">
                            1 User
                            </div>
                        </div>
                        <div className="row py-2">
                            <div className="col-12 ">
                            <p>For individuals with basic customizations.  </p> 
                            </div>
                        </div>
                        <div className="row my-2 ">
                            <div className="col-12 ">
                            <button className="btn px-4 py-1 btn-outline-success" type="submit">
              Signup for Free
            </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 py-2">
                                <h5>No Credit Card Required!</h5>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 ">
                               <ul className=" ms-3" style={{listStyle: "square"}}>
                                <li className="wfont2 py-1">Digital Business Card for yourself</li>
                                <li className="wfont2 py-1">Basic customizations</li>
                                <li className="wfont2 py-1">wCard Branding</li>
                                <li className="wfont2 py-1">Include a profile image</li>
                                <li className="wfont2 py-1">Include a cover image</li>
                                <li className="wfont2 py-1">Add Education and Experience Tabs</li>
                                <li className="wfont2 py-1">Add Contact Form</li>
                                

                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>

         <div className="row justify-content-center">
                <div className="col-12 col-md-4 mt-5">
                <NavLink to="/" className=" pb-0 text-decoration-none">
                    <h4 className="aboutcontent fw-bold ">VIPCARDS</h4>
        </NavLink>
        <p className="opacity-75 aboutcontent">NTSVIPCARDS.IN TEC-SOLVE PRIVATE LIMITED
        FLNO 13 NEELKANTH APT PLN, 21 SNO 46/3B WADGAONSHERI, Dukirkline, Pune City, Pune- 411014, Maharashtra</p>
                </div>
                
                <div className="col-5 col-md-3 mt-5">
                    <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">Company</li>
                        <li  >
                            <Link to="/about" className="text-decoration-none opacity-75 aboutcontent">About</Link>
                        </li>
                        <li  >
                            <Link to="/team" className="text-decoration-none opacity-75 aboutcontent">Leadership Team</Link>
                        </li>
                        <li  >
                            <Link to="/mission" className="text-decoration-none opacity-75 aboutcontent">IT Blogs</Link>
                        </li>
                       
                        <li  >
                            <Link to="/careers" className="text-decoration-none opacity-75 aboutcontent">Careers</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-5 col-md-3  mt-5">
                <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">IT Services</li>                    
                        <li  >
                            <Link to="/faqs" className="text-decoration-none opacity-75 aboutcontent">Help & FAQ</Link>
                        </li>
                        {/* <li  >
                            <Link to="/contactus" className="text-decoration-none opacity-75 aboutcontent">Contact Us</Link>
                        </li> */}
                       
                        <li  >
                            <Link to="/terms" className="text-decoration-none opacity-75 aboutcontent">Terms & Conditions</Link>
                        </li>
                        <li  >
                            <Link to="/privacypolicy" className="text-decoration-none opacity-75 aboutcontent">Privacy Policy </Link>
                        </li>
                        <li  >
                            <Link to="/refundpolicy" className="text-decoration-none opacity-75 aboutcontent">Refund Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="my-2"/>
        <div className="row text-center pb-3">
            <div className="col-12">Copyright © 2018 NanoSoft. Designed and Developed by LineThemes Only on Envato Market.</div>
        </div>


         </div>

         
        </>
    )
}

export default WHome;