import React from "react";
import "../src/Terms.css";

const PrivacyPolicy=()=>{
    return(
        <>
        <div className="container py-5">
      <h2 className="text-center mb-4 aboutcontent">Privacy Policy</h2>
      <h5 className="aboutcontent pb-3">
        At VIPCards, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
      </h5>

      <h5>1. Information We Collect</h5>
      <p>
        When you use VIPCards, we may collect the following types of information:
      </p>
      <ul>
        <li>Personal Information: Name, email address, business details, and contact information.</li>
        <li>Usage Data: Information about your interactions with our platform, such as pages visited and features used.</li>
        <li>Payment Information: Details provided during purchases or subscriptions (processed securely through third-party services).</li>
      </ul>

      <h5>2. How We Use Your Information</h5>
      <p>We use the collected information to:</p>
      <ul>
        <li>Provide, maintain, and improve our services.</li>
        <li>Generate digital business cards tailored to your business needs.</li>
        <li>Communicate with you about updates, promotions, or support requests.</li>
        <li>Analyze user behavior to enhance platform functionality.</li>
      </ul>

      <h5>3. Sharing Your Information</h5>
      <p>
        We do not sell or share your personal information with third parties, except in the following cases:
      </p>
      <ul>
        <li>To trusted service providers for processing payments or delivering services.</li>
        <li>To comply with legal obligations or respond to lawful requests.</li>
        <li>To protect the rights and safety of VIPCards, its users, or the public.</li>
      </ul>

      <h5>4. Data Security</h5>
      <p>
        We implement robust security measures to protect your data from unauthorized access, alteration, or destruction. However, no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
      </p>

      <h5>5. Your Rights</h5>
      <p>
        You have the right to:
      </p>
      <ul>
        <li>Access, update, or delete your personal information.</li>
        <li>Opt out of receiving promotional communications.</li>
        <li>Request a copy of the data we hold about you.</li>
      </ul>

      <h5>6. Cookies and Tracking Technologies</h5>
      <p>
        VIPCards uses cookies and similar technologies to improve user experience and analyze website usage. You can manage or disable cookies through your browser settings, but some features may be affected.
      </p>

      <h5>7. Third-Party Links</h5>
      <p>
        Our website may include links to third-party websites. We are not responsible for the privacy practices or content of these sites. Please review their privacy policies before providing any personal information.
      </p>

      <h5>8. Updates to this Privacy Policy</h5>
      <p>
        We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The updated version will be posted on our website with the effective date.
      </p>

      <h5>9. Contact Us</h5>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@vipcards.com" className="text-decoration-none">privacy@vipcards.com</a>.
      </p>
    </div>
    </>
    )
}

export default PrivacyPolicy;