import "../Home.css";
import React from "react";

const Cart = () => {
  return (
    <>
      <div className="container-fluid overflow-hidden p-0">
      <div className="d-flex categorybg mt-5 pt-5 pb-3 px-3 mb-4">
          <div>
            <h3 className="mb-0 pt-md-5">Cart</h3>
            <div className="fonttitle">Your added services</div>
          </div>
          <div className="ms-auto">
            <i class="fa-solid fs-6 iconclr fa-magnifying-glass"></i>
          </div>
        </div>

        <div className="d-flex px-3 mt-4">
          <div>
            <h5>Get these Services</h5>
          </div>
          <div className="ms-auto">
            <i class="fa-solid iconclr fs-6 bg-white fa-chevron-right rounded-1 p-2"></i>
          </div>
        </div>

        <div className="schedule my-2 px-3 mx-2">
          <div className="row">
           <div className="col-md-6">
            <div className="row">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="font3 mt-2">Hair Care Pack</div>
              <div className="font4 mt-1">By Radha Singh</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
            </div>
           </div>
           <div className="col-md-6">
           <div className="row mt-3 mt-md-0">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="font3 mt-2">Hair Care Pack</div>
              <div className="font4 mt-1">By Radha Singh</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
          </div>
           </div>
          </div>
        </div>

        <div className="schedule my-2 px-3 mx-2">
          <div className="row ">
           <div className="col-md-6">
            <div className="row">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="font3 mt-2">Hair Care Pack</div>
              <div className="font4 mt-1">By Radha Singh</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
            </div>
           </div>
           <div className="col-md-6">
           <div className="row mt-3 mt-md-0">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="font3 mt-2">Hair Care Pack</div>
              <div className="font4 mt-1">By Radha Singh</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
          </div>
           </div>
          </div>
        </div>


         
        
      </div>
    </>
  );
};
export default Cart;
