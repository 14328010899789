import React from "react";
import "../Home.css";
import "./Wcard.css";
import "./Mission.css";
import { NavLink } from "react-router-dom";
import team from "./images/whyus.jpg";
import { Link } from "react-router-dom";

const WhyUs=()=>{
    return(
        <>
        <div className="container-fluid p-0 mt-5 pt-5 overflow-hidden">
            <div className="row pt-5 justify-content-center">
                <div className="col-md-4">
                <img src={team} className="img-fluid" alt="whyus"/>
                </div>
                <div className="col-md-6 my-auto">
                    <h2 className="aboutcontent">Why NanoSoft IT Services?</h2>
                    <p className="aboutcontent opacity-75">NanoSoft have the expertise and resources required to design, develop and manage the highly available and highly secure technology platform that you need, giving you the time and confidence to focus on running your business.</p>
                    <p className="aboutcontent opacity-75">Here are 4 reasons why you should choose us to build your infrastructure, support your people and systems, as well as advise you on projects that will reduce your risk, enhance your productivity and give you a real competitive edge.</p>
                </div>
            </div>


            <div className="row justify-content-center container mx-auto my-5">
                <div className="col">
                    <h2 className="aboutcontent"><i class="fa-solid fa-user-group"></i></h2>
                    <h3 className="aboutcontent">People</h3>
                    <p className="opacity-75 aboutcontent">We understand that our people impact the success of our business, and we hire people who are smart, dedicated for NanoSoft.</p>
                </div>
                <div className="col">
                    <h2 className="aboutcontent"><i class="fa-solid fa-mobile-screen-button"></i></h2>
                    <h3 className="aboutcontent">Customer Service</h3>
                    <p className="opacity-75 aboutcontent">We strive to provide superior customer service and ensure that every client is completely satisfied with our work.</p>
                </div>
                <div className="col">
                    <h2 className="aboutcontent"><i class="fa-solid fa-life-ring"></i></h2>
                    <h3 className="aboutcontent">Support</h3>
                    <p className="opacity-75 aboutcontent">Our engineers are trustworthy, dedicated and experienced and will go the extra mile to solve your IT issues.</p>
                </div>
                <div className="col">
                    <h2 className="aboutcontent"><i class="fa-solid fa-crown"></i></h2>
                    <h3 className="aboutcontent">Quality</h3>
                    <p className="opacity-75 aboutcontent">We are committed to deliver outstanding, cutting edge IT solutions that add real value that goes beyond what is expected.</p>
                </div>
            </div>

            <div className="row py-4 text-center aboutcontent">
                <h3>Experience the future of networking today.</h3>
                <h4>Choose our digital business cards to create, share, and connect effortlessly.</h4>
            </div>

            <hr/>


            <div className="row justify-content-center">
            <div className="col-12 col-md-4 mt-5">
                <NavLink to="/" className=" pb-0 text-decoration-none">
                    <h4 className="aboutcontent fw-bold ">VIPCARDS</h4>
        </NavLink>
        <p className="opacity-75 aboutcontent">NTSVIPCARDS.IN TEC-SOLVE PRIVATE LIMITED
        FLNO 13 NEELKANTH APT PLN, 21 SNO 46/3B WADGAONSHERI, Dukirkline, Pune City, Pune- 411014, Maharashtra</p>
                </div>
                
                <div className="col-5 col-md-3 mt-5">
                    <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">Company</li>
                        <li  >
                            <Link to="/about" className="text-decoration-none opacity-75 aboutcontent">About</Link>
                        </li>
                        <li  >
                            <Link to="/team" className="text-decoration-none opacity-75 aboutcontent">Leadership Team</Link>
                        </li>
                        <li  >
                            <Link to="/mission" className="text-decoration-none opacity-75 aboutcontent">IT Blogs</Link>
                        </li>
                       
                        <li  >
                            <Link to="/careers" className="text-decoration-none opacity-75 aboutcontent">Careers</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-5 col-md-3  mt-5">
                <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">IT Services</li>                    
                        <li  >
                            <Link to="/faqs" className="text-decoration-none opacity-75 aboutcontent">Help & FAQ</Link>
                        </li>
                        {/* <li  >
                            <Link to="/contactus" className="text-decoration-none opacity-75 aboutcontent">Contact Us</Link>
                        </li> */}
                       
                        <li  >
                            <Link to="/terms" className="text-decoration-none opacity-75 aboutcontent">Terms & Conditions</Link>
                        </li>
                        <li  >
                            <Link to="/privacypolicy" className="text-decoration-none opacity-75 aboutcontent">Privacy Policy </Link>
                        </li>
                        <li  >
                            <Link to="/refundpolicy" className="text-decoration-none opacity-75 aboutcontent">Refund Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="my-2"/>
        <div className="row text-center pb-3">
            <div className="col-12">Copyright © 2018 NanoSoft. Designed and Developed by LineThemes Only on Envato Market.</div>
        </div>
        </div>
        </>
    )
}

export default WhyUs;