import React from "react";
import "../Home.css";
import "./Wcard.css";
import "./Mission.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Mission =()=>{
    return(
        <>
            <div className="container-fluid p-0">
            <div className="row mt-5 pt-5">
                <div className="col-12 text-center my-5">
                    <h1 className="aboutcontent fw-bold">Mission, Vision & Values</h1>
                    <p className="aboutcontent opacity-75">We connect with our clients, our communities, and our company. It makes us <br/> different. It makes us better.</p>
                </div>
            </div>

            <div className="container">
            <div className="row">
                <div className="col-md-6 colbg1 pt-3 pb-5  ps-4">
                    <h2 className="py-3 px-5 aboutcontent fw-bold text-center">Our Culture</h2>
                    <p className="aboutcontent px-5 opacity-75">We communicate clearly, because in a world crowded <br/> with over-the-top marketing, simple wins.</p>
                    <p className="aboutcontent px-5 opacity-75">We are not rock stars, ninjas or any other disingenuous <br/>buzz term. We are creative professionals.</p>
                    <p className="aboutcontent px-5 opacity-75">Before you can market a business, you need to know <br/> its story.</p>
                    <div className="text-center"><button className="colbg3 rounded-2 px-5 text-white border-0 py-2 ">Find out more</button></div>
                </div>
                <div className="col-md-6 colbg2 pt-3 pb-5 ps-4">
                    <h2 className="py-3 px-5 fw-bold text-white text-center">Our Commitment</h2>
                    <p className="text-light px-5 opacity-75 ">We’re the trusted partner that takes a proactive <br/> approach to your technology.</p>
                    <p className="text-light px-5 opacity-75">We’re the fellow small business owner that understand <br/> your day-to-day needs.</p>
                    <p className="text-light px-5 opacity-75">We’re the built-in IT support staff that’s always there <br/> when you need us.</p>
                    <div className="text-center"><button className="colbg1 rounded-2 px-5 aboutcontent border-0 py-2 ">Find out more</button></div>
                </div>
            </div>
</div>
            
                    <div className="row  mx-auto py-5 mt-3 justify-content-center">
                        <div className="col-md-4 text-center ">
                            <h3>Our Mission</h3>
                        </div>
                        <div className="col-md-6 opacity-75">
                        <p>To be the global leader in IT Services. Building on our technologies, competencies and customer interests, and creating value for our stakeholders and customers. We’ll achieve this by focusing on the intersection of our client’s emerging needs and the acceleration of business and technological change</p>
                        </div>
                    </div>
                    <div className="row mx-auto visionbg pt-5 pb-5 justify-content-center">
                        <div className="col-md-4 text-center ">
                            <h3>Our Vision</h3>
                        </div>
                        <div className="col-md-6  opacity-75">
                        <p>NanoSoft will be the first choice solution partner in the technology sector providing IT and Communication services to businesses throughout the region.</p>
                        <p>We will become an integral part of our client’s success, collaborating with them to achieve their strategic objectives whilst creating long lasting business value through the delivery and management of their technology.</p>
                        <p>Our team of talented, experienced and motivated industry specialists will be recognised as the best in their field. Our continued success will be driven by our people who will share in it.</p>
                        </div>
                    </div>
                    <div className="row mx-auto py-5 justify-content-center">
                        <div className="col-md-4 text-center ">
                            <h3>Our Values</h3>
                        </div>
                        <div className="col-md-6 opacity-75">
                        <p>Our values are the guiding principles upon which NanoSoft was founded and how we strive to conduct our business on a daily basis.</p>
                        <p>Values establish our view of the world as we shape the future. They determine how we treat each other. Our values are to:</p>
                        </div>
                    </div>
             

                    <div className="row justify-content-center">
                    <div className="col-12 col-md-4 mt-5">
                <NavLink to="/" className=" pb-0 text-decoration-none">
                    <h4 className="aboutcontent fw-bold ">VIPCARDS</h4>
        </NavLink>
        <p className="opacity-75 aboutcontent">NTSVIPCARDS.IN TEC-SOLVE PRIVATE LIMITED
        FLNO 13 NEELKANTH APT PLN, 21 SNO 46/3B WADGAONSHERI, Dukirkline, Pune City, Pune- 411014, Maharashtra</p>
                </div>
                
                <div className="col-5 col-md-3 mt-5">
                    <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">Company</li>
                        <li  >
                            <Link to="/about" className="text-decoration-none opacity-75 aboutcontent">About</Link>
                        </li>
                        <li  >
                            <Link to="/team" className="text-decoration-none opacity-75 aboutcontent">Leadership Team</Link>
                        </li>
                        <li  >
                            <Link to="/mission" className="text-decoration-none opacity-75 aboutcontent">IT Blogs</Link>
                        </li>
                       
                        <li  >
                            <Link to="/careers" className="text-decoration-none opacity-75 aboutcontent">Careers</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-5 col-md-3  mt-5">
                <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">IT Services</li>                    
                        <li  >
                            <Link to="/faqs" className="text-decoration-none opacity-75 aboutcontent">Help & FAQ</Link>
                        </li>
                        {/* <li  >
                            <Link to="/contactus" className="text-decoration-none opacity-75 aboutcontent">Contact Us</Link>
                        </li> */}
                       
                        <li  >
                            <Link to="/terms" className="text-decoration-none opacity-75 aboutcontent">Terms & Conditions</Link>
                        </li>
                        <li  >
                            <Link to="/privacypolicy" className="text-decoration-none opacity-75 aboutcontent">Privacy Policy </Link>
                        </li>
                        <li  >
                            <Link to="/refundpolicy" className="text-decoration-none opacity-75 aboutcontent">Refund Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="my-2"/>
        <div className="row text-center pb-3">
            <div className="col-12">Copyright © 2018 NanoSoft. Designed and Developed by LineThemes Only on Envato Market.</div>
        </div>

                </div>



            
        </>
    )
}

export default Mission;