
import "../Home.css";
import "./Wcard.css"
import React from "react";

const Homepage = () => {
  const categories = [
    {
      name: "Haircut",
      img: "https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    },
    {
      name: "Coloring",
      img: "https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    },
    {
      name: "Spa",
      img: "https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    },
    {
      name: "Makeup",
      img: "https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    },
    {
      name: "Styling",
      img: "https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    },
  ];

  return (
    <>
      <div className="container-fluid">
        {/* <div className="row align-items-center mt-5 py-4">
          <div className="col-auto col-md-3 text-center">
            <img
              className="salon-logo"
              src="https://images.pexels.com/photos/27914301/pexels-photo-27914301/free-photo-of-reflexion-urbana.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt="profile"
            />
          </div>
          <div className="col col-md-6 text-md-center mt-5">
            <div className="header-details pt-3">
              <h2 className="d-none d-md-block ">Vaishnavi Beauty Salon</h2>
              <h6 className="d-block d-md-none">Vaishnavi Beauty Salon</h6>
              <p className="">
                <i class="fa-solid fa-location-arrow "></i> 52WQ+2X5, New South
                Wales
              </p>
            </div>
          </div>
          <div className="col-auto col-md-3 text-md-center">
            <h5>
              <i class="fa-solid iconfont fa-bell bell"></i>
            </h5>
          </div>
        </div> */}
        <div className="row mt-4 pt-5">
          <div className="col-12 mt-4 pt-5">
            <div className="search-bar">
              <input
                type="text"
                placeholder="&#128269; Search for any services you desire"
              />
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center my-4">
          {categories.map((category, index) => (
            <div className="col" key={index}>
              <img
                className="salon-logo"
                src={category.img}
                alt={category.name}
              />
              <p className="font1 text-center">{category.name}</p>
            </div>
          ))}
        </div> */}
        <div className="d-flex  justify-content-center">
          {categories.map((category, index) => (
            <div className="mx-2 m-md-4 mx-lg-5" key={index}>
              <img
                className="salon-logo"
                src={category.img}
                alt={category.name}
              />
              <p className="text-center font3">{category.name}</p>
            </div>
          ))}
        </div>
        <div className="special-offers">
          <h5 className="my-4 specialfont">#SpecialOffers</h5>
          {/* {offers.map((offer) => (
            <div className="offer-card" key={offer.id}>
              <img src={offer.image} alt={offer.title} />
              <div className="offer-details">
                <h3>{offer.title}</h3>
                <p>{offer.description}</p>
                <button>Claim Now</button>
              </div>
            </div>
          ))} */}

          {/* <div className="offer-card" key={offer.id}>
            <img src={offer.image} alt={offer.title} />
          <div className="offer-details">
            <h3>{offer.title}</h3>
              <p>{offer.description}</p>
            <button>Claim Now</button>
          </div>
        </div> */}
          <div id="carouselExampleIndicators" class="carousel slide">
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner rounded-4">
              <div class="carousel-item active">
                <img
                  className="carousel-img"
                  src="https://images.pexels.com/photos/24205867/pexels-photo-24205867/free-photo-of-cat-sitting-near-building-wall.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  
                  alt="carousel1"
                />
              </div>
              <div class="carousel-item">
                <img
                  className="carousel-img"
                  src="https://images.pexels.com/photos/12465540/pexels-photo-12465540.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  alt="carousel2"
                />
              </div>
              <div class="carousel-item">
                <img
                  className="carousel-img"
                  src="https://images.pexels.com/photos/18445718/pexels-photo-18445718/free-photo-of-children-on-carousel-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  alt="carousel3"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        {/* end of special offers */}
       
       <div className="d-flex my-4">
          <div>
            <h5 className="specialfont">Upcoming Schedule</h5>
          </div>
          <div className="ms-auto">
            <i class="fa-solid iconfont iconclr fa-calendar-days"></i>
          </div>
       
       
        </div>

        <div className="schedule1">
          <div className="row container mx-auto">
           <div className="col-md-6">
           <div className="row">
           <div className="col-3 col-md-5">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img"
              />
            </div>
            <div className="col-7 ms-3 ms-md-0 col-md-5">
              <div className="font2">Body Grooming</div>
              <div className="font3 mt-2">Radha Singh</div>
              <div className="font4">Hairstylist</div>
            </div>  
            <div className="col-1">
              <i className="fa-solid fa-phone phone  text-white rounded-1 p-2"></i>
            </div>
           </div>
          </div>
          <div className="col-md-6 py-5 py-md-0">
           <div className="row">
           <div className="col-3 col-md-5">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img"
              />
            </div>
            <div className="col-7 ms-3 ms-md-0 col-md-5">
              <div className="font2">Body Grooming</div>
              <div className="font3 mt-2">Radha Singh</div>
              <div className="font4">Hairstylist</div>
            </div>  
            <div className="col-1">
              <i className="fa-solid fa-phone phone  text-white rounded-1 p-2"></i>
            </div>
           </div>
          </div>
           </div>

          <div className=" pipebg px-2 py-1 d-flex d-md-none ">
            <div>
              <div className="d-flex">
                <div>
                  <i class="fa-solid iconclr pe-2 fa-calendar"></i>
                </div>
                <div>
                  <div className="font2">Monday, 26 May</div>
                </div>
              </div>
            </div>
            <div className="fw-bold pipeclr px-2">|</div>
            <div>
              <div className="d-flex">
                <div>
                  <i class="fa-solid iconclr pe-2 fa-clock"></i>
                </div>
                <div>
                  <div className="font2">09:00 - 10:00</div>
                </div>
              </div>
            </div>
          </div>



<div className="hidesection">
  
<div className="mt-3 pipebg px-2 py-1 ">
            <div className="row justify-content-center">
              <div className="col-5">
               <div className="row">
               <div className="col-6">
                  <i class="fa-solid iconclr pe-2 fa-calendar"></i>
                </div>
                <div className="col-6">
                  <div className="font1">Monday, 26 May</div>
                </div>
               </div>
              </div>
              <div className="col-2">
              <div className="fw-bold pipeclr px-2">|</div>
              </div>

              <div className="col-5">
                <div className="row">
                <div className="col-6"> 
                  <i class="fa-solid iconclr pe-2 fa-clock"></i>
                </div>
                <div className="col-6">
                  <div className="font1">09:00 - 10:00</div>
                </div>
                </div>
              </div>
            </div>
            
            <div>
              
            </div>
          </div>
</div>

          
          
        </div>

        <div className="d-flex my-4">
          <div>
            <h5 className="specialfont">Popular Services for you</h5>
          </div>
          <div className="ms-auto link more">More</div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
