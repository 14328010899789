


import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Wcard from "./Pages/Wcard";
import Main from "./Pages/Main";
import SignUp from "./Pages/SignUp";
import LogIn from "./Pages/LogIn";
import HomePage from "./Pages/HomePage";
import Footer from "./Pages/Footer";
import Categories from "./Pages/Categories";
import Cart from "./Pages/Cart";
import Offers from "./Pages/Offers";
import Profile from "./Pages/Profile";
import BusinessCard from "./Pages/BusinessCard";
import Header from "./Pages/Header";
import LoginForm from "./Pages/LoginForm";
import About from "./Pages/About";
import WHeader from "./Pages/WHeader";
import Mission from "./Pages/Mission";
import Teams from "./Pages/Teams";
import WhyUs from "./Pages/WhyUs";
import Location from "./Pages/Location";
import Career from "./Pages/Career";
import Faq from "./Pages/Faq";
import Pricing from "./Pages/Pricing";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import RefundPolicy from "./RefundPolicy";
import WFooter from "./Pages/WFooter";


import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";



function App() {
  const location = useLocation();
  const showFooter = !["/signup", "/login", "/card", "/" ,"/privacypolicy", "/terms", "/refundpolicy"].includes(location.pathname); 
  // const showFooter1 =!["/home", "/categories", "/cart", "/offers", "/profile", "/card", "/signup", "/login", "/card"]
  const showFooter1 = ["/", "/about", "/mission", "/team", "/whyus", "/locations", "/careers", "/faqs", "/pricing"].includes(location.pathname);
  const showHeader = ["/home", "/categories", "/cart", "/offers", "/profile", "/card"].includes(location.pathname);
  const showHeader1 = ["/", "/about", "/mission", "/team", "/whyus", "/locations", "/careers", "/faqs", "/pricing"].includes(location.pathname);

  // const showWFooter = ["/", "/about", "/mission"].includes(location.pathname);
  return (
    <>
      {showHeader && <Header />}
      {showHeader1 && <WHeader/>}

      <main>
        <Routes>
          <Route path="/" element={<Wcard/>}/>
          <Route path="about" element={<About/>}/>
          <Route path="/mission" element={<Mission/>}/>
          <Route path="/whyus" element={<WhyUs/>}/>
          <Route path="/locations" element={<Location/>}/>
          <Route path="/careers" element={<Career/>}/>
          <Route path="/faqs" element={<Faq/>}/>
          <Route path="/main" element={<Main />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/offers" element={<Offers/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/card" element={<BusinessCard/>}/>
          <Route path="/form" element={<LoginForm/>}/>
          <Route path="/team" element={<Teams/>}/>
          <Route path="/pricing" element={<Pricing/>}/>
          <Route path="/terms" element={<TermsAndConditions/>}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
          <Route path="/refundpolicy" element={<RefundPolicy/>}/>

        </Routes>
      </main>

      {/* {showWFooter && <WFooter />} */}
     
      {showFooter && <Footer />} 
      {showFooter1 && <WFooter/>}
      

      {/* <main>
      <Routes>
        <Route path="/" element={<Wcard/>}/>
        <Route path="/form" element={<LoginForm/>}/>
      </Routes>
      </main> */}
    </>
  );
}

export default App;
