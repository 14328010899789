import React from "react";
import "../Home.css";

const Profile =()=>{
    return(
        <>
        <div className="containier-fluid overflow-hidden p-0">
        <div className="d-flex  px-3 pb-3 pt-5 mt-5">
          <div>
            <h3 className="mb-0 mt-md-5">Profile</h3>
            <div className="fonttitle">Check out our amazing offers</div>
          </div>
          <div className="ms-auto mt-md-5">
            <i class="fa-solid fs-6 iconclr fa-magnifying-glass"></i>
          </div>
        </div>
        <p className="text-center fw-bold py-2 accountbg">Your Account</p>

        <div className="schedule my-md-2 px-3 mx-2">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
              <div className="col-1 ">
            <i class="fa-solid fa-user font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Account Details</div>
              
            </div>
            <div className="col-2 ms-auto">
            
              <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="row">
            <div className="col-1 ">
            <i class="fa-solid fa-bookmark font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">My Bookings</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
          </div>
            </div>
          </div>
        </div>

        


        <div className="schedule mt-md-3 px-3 mx-2">
          <div className="row">
           <div className="col-md-6">
            <div className="row">
            <div className="col-1 ">
            <i class="fa-regular fa-address-card font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Share a Business Card</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
            </div>
           </div>
           <div className="col-md-6">
           <div className="row">
            <div className="col-1 ">
            <i class="fa-regular fa-handshake font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Register as a Business</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
          </div>
           </div>
          </div>
        </div>
        
        
        
        <div className="schedule mt-md-3 px-3 mx-2">
          <div className="row">
          <div className="col-md-6">
           <div className="row">
            <div className="col-1 ">
            <i class="fa-solid fa-coins font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Refer & Earn</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
          </div>
           </div>
           <div className="col-md-6">
           <div className="row">
            <div className="col-1 ">
            <i class="fa-solid fa-building-shield font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Privacy Policy</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
          </div>
           </div>
          </div>
        </div>
        
        
        
        <div className="schedule mt-md-3 px-3 mx-2">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
              <div className="col-1 ">
            <i class="fa-solid fa-mobile-retro font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Contact Us</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="row">
            <div className="col-1 ">
            <i class="fa-solid fa-arrow-right-from-bracket font6"></i>
            </div>
            <div className="col-9 ps-2">
              <div className="fontprofile ps-2">Log Out</div>
              
            </div>
            <div className="col-2 ms-auto">
             
            <i class="fa-solid fa-circle-play fs-6 iconbg iconclr rounded-1 p-2"></i>
            </div>
          </div>
            </div>
          </div>
        </div>
        
        
        </div>
        </>
    )
}

export default Profile;