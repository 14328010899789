import React from "react";
import { NavLink } from "react-router-dom";
import "./WFooter.css";

const WFooter = () => {
  const handleShare = async () => {
    const shareData = {
      title: "VIPCards",
      text: "Check out VIPCards, your go-to platform for business cards!",
      url: "https://www.vipcards.com",
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log("Website link shared successfully!");
      } else {
        alert("Sharing is not supported in this browser.");
      }
    } catch (err) {
      console.error("Error while sharing:", err);
    }
  };

  return (
    <footer className="mobile-footer bg-light">
      <div className="footer-nav px-2">
        <NavLink to="/" className="footer-icon">
          <div className="iconfont">
            <i className="fa-solid fa-house"></i>
          </div>
          <div>Home</div>
        </NavLink>
        <NavLink to="/services" className="footer-icon">
          <div className="iconfont">
            <i className="fa-solid fa-cogs"></i>
          </div>
          <div>Services</div>
        </NavLink>
        <button className="footer-icon share-btn border-0 bg-transparent" onClick={handleShare}>
          <div className="iconfont">
            <i className="fa-solid fa-share"></i>
          </div>
          <div>Share</div>
        </button>
        <NavLink to="/profile" className="footer-icon">
          <div className="iconfont">
            <i className="fa-solid fa-user"></i>
          </div>
          <div>Profile</div>
        </NavLink>
      </div>
    </footer>
  );
};

export default WFooter;
