import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../Home.css";
import "./Wcard.css";
import "./Header.css";

const WHeader = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference for the menu

  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setIsDarkMode(true); // Set dark mode if saved in localStorage
      document.body.classList.add("dark-mode"); // Apply dark mode to body
    } else {
      document.body.classList.remove("dark-mode"); // Apply light mode
    }
  }, []);


  // Close the menu if clicking outside the menu
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  // Add event listener for clicks outside the menu
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

 

  // Handle the theme toggle
  const handleThemeToggle = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light");
      if (newMode) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
      return newMode;
    });
  };

  const handleWhatsAppShare = () => {
    const message = encodeURIComponent(
      "Check out VIPCards for creating your digital business card! https://vipcards.com"
    );
    const whatsappUrl = `https://wa.me/?text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    // <header
    //   className={`header fixed-top py-2 shadow-sm ${
    //     isMenuOpen
    //       ? isDarkMode
    //         ? "sticky-header bg-dark"
    //         : "sticky-header bg-white"
    //       : "bg-transparent"
    //   }`}
    // >

    <header
  className={`header fixed-top py-2 shadow-sm ${
    isMenuOpen
      ? isDarkMode
        ? "sticky-header bg-dark"
        : "sticky-header bg-white"
      : "bg-white"
  }`}
>
      <nav className="navbar navbar-expand-lg">
        {/* Toggler button moved to the left */}
        <button
          className="navbar-toggler me-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isMenuOpen ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={handleToggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Brand Name Centered */}
        <NavLink to="/" className="navbar-brand mx-auto pb-0 customlogo">
          <h5 className="brand-text fw-bold">
            <span className="text-warning">VIP</span>
            <span className="text-white">CARDS</span>
          </h5>
        </NavLink>

       

        {/* Collapsible Content */}
        <div
          ref={menuRef} // Add reference here
          className={`custom-collapse ${isMenuOpen ? "show" : ""} collapse-end`}
          id="navbarNav"
        >
          <ul className="navbar-nav me-auto">
<li className="nav-item">
  <NavLink to="/" className="nav-link" activeClassName="active">
    Home
  </NavLink>
</li>
<li className="nav-item">
  <NavLink to="/about" className="nav-link">
    About
  </NavLink>
</li>
<li className="nav-item d-none d-md-block">
  <NavLink to="/mission" className="nav-link">
    Mission
  </NavLink>
</li>
<li className="nav-item d-none d-md-block">
  <NavLink to="/team" className="nav-link">
    Teams
  </NavLink>
</li>
<li className="nav-item d-none d-md-block">
  <NavLink to="/whyus" className="nav-link">
    Why Us
  </NavLink>
</li>
<li className="nav-item d-none d-md-block">
  <NavLink to="/locations" className="nav-link">
    Locations
  </NavLink>
</li>
<li className="nav-item">
  <NavLink to="/faqs" className="nav-link">
    FAQs
  </NavLink>
</li>
<li className="nav-item d-none d-md-block">
  <NavLink to="/careers" className="nav-link">
    Careers
  </NavLink>
</li>
<li className="nav-item">
  <NavLink to="/pricing" className="nav-link">
    Pricing
  </NavLink>
</li>

</ul>
 {/* WhatsApp Icon */}

          {/* Theme Toggle */}
          <div className="form-check my-auto form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              checked={isDarkMode}
              onChange={handleThemeToggle}
            />
          </div>

          <div className="ms-auto">
            <NavLink
              to="/login"
              className="btn btn-link text-success text-decoration-none me-4"
            >
              Login
            </NavLink>
            <NavLink to="/form" className="btn btn-outline-success" type="submit">
              Create a Card
            </NavLink>
          </div>
        </div>
        <button
          className="btn btn-link  whatsapp-btn p-0"
          onClick={() => handleWhatsAppShare()}
          aria-label="Share on WhatsApp"
        >
          <i className="fa-brands me-3 fa-whatsapp text-success fs-2"></i>
        </button>
      </nav>
    </header>
  );
};

export default WHeader;







          
