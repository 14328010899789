import React from "react";
import "../Home.css";

const Categories = () => {
  return (
    <>
      <div className="container-fluid overflow-hidden p-0">
        <div className="d-flex categorybg px-2 pt-5 mt-5">
          <div>
            <h4 className="specialfont mt-md-5">Categories</h4>
          </div>
          <div className="ms-auto mt-md-5">
            <i class="fa-solid fs-6 iconclr fa-magnifying-glass"></i>
          </div>
        </div>

        <div className="d-flex px-2 mt-2">
          <div>
            <h5 className="servicefont">Get these Services</h5>
          </div>
          <div className="ms-auto">
            <i class="fa-solid iconclr fs-6 iconbg fa-chevron-right rounded-1 p-2"></i>
          </div>
        </div>

        <div className="row ps-2 footer-icon ">
          <div className="col-5">
            <p>By Radha Singh</p>
          </div>
          <div className="col-6 p-0">
            <hr className="fw-bold" />
          </div>
        </div>

        <div className="schedule">
          <div className="row">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="fontcare mt-2">Hair Care Pack</div>
              <div className="font4 mt-1">By Radha Singh</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
          </div>
        </div>

        <div className="schedule my-2">
          <div className="row">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="fontcare mt-2">Hair Extension</div>
              <div className="font4 mt-1">By Namita Pawar</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
          </div>
        </div>

        <div className="schedule my-2">
          <div className="row">
            <div className="col-3 ">
              <img
                src="https://images.pexels.com/photos/15862026/pexels-photo-15862026/free-photo-of-man-posing-topless-with-arms-raised.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                alt="profile"
                className=" profile-img2"
              />
            </div>
            <div className="col-7 mx-auto">
              <div className="fontcare mt-2">Hair Extension</div>
              <div className="font4 mt-1">By Namita Pawar</div>
            </div>
            <div className="col-2">
              {/* <i className="fa-solid fa-phone font1 text-white rounded-1 p-2"></i> */}
              <i class="fa-solid fa-plus fs-6 iconbg iconclr rounded-1 p-2 mt-3"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
