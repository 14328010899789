import "../App.css";

const Main = () => {
  return (
    <>
     <div className="container-fluid p-0 overflow-hidden">
     <div id="carouselExampleIndicators" class="carousel slide">
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active text-center">
            <img
              class=" img d-block w-100"
              src="https://images.pexels.com/photos/26447251/pexels-photo-26447251/free-photo-of-cat-laying-against-pink-wall-background.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt="Img not found"
            />
          </div>
          <div class="carousel-item text-center">
            <img
              class=" img d-block w-100"
              src="https://images.pexels.com/photos/18738855/pexels-photo-18738855/free-photo-of-film-photo-of-a-cherry-blossom.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt="Img not found"
            />
          </div>
          <div class="carousel-item text-center">
            <img
              class=" img d-block w-100"
              src="https://images.pexels.com/photos/10972983/pexels-photo-10972983.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
              alt="Img not found"
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <div class="row mt-5">
        <div class="col-sm-12 text-center">
          <button type="button " class=" text-white border py-2 w-50  btnbg">
            Get Started
          </button>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-sm-12 text-center">
          <p>
            <span>Already have an account?</span>{" "}
            <span class="textclr"> Sign In</span>{" "}
          </p>
        </div>
      </div>
     </div>
    </>
  );
};

export default Main;
