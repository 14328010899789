import "../App.css";
import { NavLink } from "react-router-dom";

const SignUp = () => {
  return (
    <>
      <body class="bodybg">
        <div class="container my-md-auto">
          <div class="row">
            <div class="col-md-12">
              <div class="font2">Create an account,</div>
              <div class="font1">
                Please type full information bellow and we can create your
                account
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12 col-md-6 my-2">
              <input
                type="text"
                class="form-control border-0"
                placeholder="First name"
                aria-label="First name"
                
              />

            </div>
            <div class="col-12 col-md-6 my-2">
              <input
                type="email"
                class="form-control border-0"
                placeholder="Email"
                aria-label="Email"
              />
            </div>
            <div class="col-12 col-md-6 my-2">
              <input
                type="text"
                class="form-control border-0"
                placeholder="Phone Number"
                aria-label="Phone Number"
              />
            </div>
            <div class="col-12 col-md-6 my-2">
              <input
                type="password"
                class="form-control border-0"
                placeholder="Password"
                aria-label="Password"
              />
            </div>
            <div class="col-12 col-md-6 my-2">
              <input
                type="password"
                class="form-control border-0"
                placeholder="Confirm the Password"
                aria-label="Confirm the Password"
              />
            </div>
          </div>
          <div class="row">
            <div class="col 12">
              <div class="font3">
                By signing up you agree to our{" "}
                <span class="textclr">Term of use and privacy</span> notice
              </div>
            </div>
          </div>

          <div class="row my-5">
            <div class="col-6">
              <button
                type="button"
                class="borderrad py-2 w-100 px-md-5 textclr"
              >
                Register as Business
              </button>
            </div>
            <div class="col-6">
              <button
                type="button"
                class="borderrad py-2 h-100  w-100 px-md-5 text-white fw-bold btnbg"
              >
                Sign Up Now
              </button>
            </div>
          </div>

          <div class="row mt-5 ">
            <div class="col-sm-12 ">
              <button
                type="button"
                class="borderrad py-2 px-md-5 w-100 textclr"
              >
                <i class="fa-brands fa-google "></i> Sign Up with Google
              </button>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-sm-12 text-center">
              <p>
                <span>Already have an account?</span>{" "}
                <NavLink to="/login" className="textclr text-decoration-none"> Sign In</NavLink>
              </p>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default SignUp;
