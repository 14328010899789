import React from "react";
import "../Home.css";
import "./Wcard.css";
import "./Mission.css";
import { NavLink } from "react-router-dom";
import flag1 from "./images/001-united-states.png";
import flag2 from "./images/002-germany.png";
import flag3 from "./images/003-russia.png";
import { Link } from 'react-router-dom';


const Location =()=>{
    return(
        <>
        <div className="container-fluid mt-5 pt-5 p-0 overflow-hidden">
       <div className="row pt-5">
        <div className="col-12 text-center aboutcontent">
            <h1 className="fw-bold">Located Around the World</h1>
            <p className="opacity-75">With 3+ regions across the globe, we can get your database or messaging service there.</p>
        </div>
       </div>

       <div className="row justify-content-center py-5">
        <div className="col-md-3 bg-white mx-3 shadow p-4 aboutcontent text-center">
            <h4>United States</h4>
            <p className=" opacity-75">2307 Beverley Rd Brooklyn, New York 11226 United States.</p>
                <img src={flag1} className="img-fluid w-25" alt="flag1"/>
        </div>  
        <div className="col-md-3 bg-white mx-3 shadow p-3 aboutcontent text-center">
            <h4>Germany</h4>
            <p className=" opacity-75">2307 Beverley Rd Brooklyn, New York 11226 United States.</p>
                <img src={flag2} className="img-fluid w-25" alt="flag1"/>
        </div> 
        <div className="col-md-3 bg-white mx-3 shadow p-3 aboutcontent text-center">
            <h4>Russia</h4>
            <p className=" opacity-75">2307 Beverley Rd Brooklyn, New York 11226 United States.</p>
                <img src={flag3} className="img-fluid w-25" alt="flag1"/>
        </div> 
       </div>
       <hr/>


       <div className="row justify-content-center">
       <div className="col-12 col-md-4 mt-5">
                <NavLink to="/" className=" pb-0 text-decoration-none">
                    <h4 className="aboutcontent fw-bold ">VIPCARDS</h4>
        </NavLink>
        <p className="opacity-75 aboutcontent">NTSVIPCARDS.IN TEC-SOLVE PRIVATE LIMITED
        FLNO 13 NEELKANTH APT PLN, 21 SNO 46/3B WADGAONSHERI, Dukirkline, Pune City, Pune- 411014, Maharashtra</p>
                </div>
                
                <div className="col-5 col-md-3 mt-5">
                    <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">Company</li>
                        <li  >
                            <Link to="/about" className="text-decoration-none opacity-75 aboutcontent">About</Link>
                        </li>
                        <li  >
                            <Link to="/team" className="text-decoration-none opacity-75 aboutcontent">Leadership Team</Link>
                        </li>
                        <li  >
                            <Link to="/mission" className="text-decoration-none opacity-75 aboutcontent">IT Blogs</Link>
                        </li>
                       
                        <li  >
                            <Link to="/careers" className="text-decoration-none opacity-75 aboutcontent">Careers</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-5 col-md-3  mt-5">
                <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">IT Services</li>                    
                        <li  >
                            <Link to="/faqs" className="text-decoration-none opacity-75 aboutcontent">Help & FAQ</Link>
                        </li>
                        {/* <li  >
                            <Link to="/contactus" className="text-decoration-none opacity-75 aboutcontent">Contact Us</Link>
                        </li> */}
                       
                        <li  >
                            <Link to="/terms" className="text-decoration-none opacity-75 aboutcontent">Terms & Conditions</Link>
                        </li>
                        <li  >
                            <Link to="/privacypolicy" className="text-decoration-none opacity-75 aboutcontent">Privacy Policy </Link>
                        </li>
                        <li  >
                            <Link to="/refundpolicy" className="text-decoration-none opacity-75 aboutcontent">Refund Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="my-2"/>
        <div className="row text-center pb-3">
            <div className="col-12">Copyright © 2018 NanoSoft. Designed and Developed by LineThemes Only on Envato Market.</div>
        </div>
        </div>
        </>
    )
}

export default Location;