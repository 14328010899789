import React from "react";
import "../Home.css";


const Offers =()=>{
    return(
        <>
         <div className="container-fluid overflow-hidden p-0">
      <div className="d-flex categorybg px-3 pt-5 mt-5 mb-4">
          <div>
            <h3 className="mb-0 pt-md-5 pt-md-5">Offers</h3>
            <div className="fonttitle">Check out our amazing offers</div>
          </div>
          <div className="ms-auto">
            <i class="fa-solid fs-6 iconclr fa-magnifying-glass"></i>
          </div>
        </div>

        <div className="d-flex px-3 mt-4">
          <div>
            <h5>Continue Playing</h5>
          </div>
          <div className="ms-auto">
            <i class="fa-solid iconclr fs-6 bg-white fa-chevron-right rounded-1 p-2"></i>
          </div>
        </div>


        <div id="carouselExampleIndicators" class="carousel slide my-3 m-3">
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div class="carousel-inner rounded-4">
              <div class="carousel-item active">
                <img
                  className="carousel-img"
                  src="https://images.pexels.com/photos/24205867/pexels-photo-24205867/free-photo-of-cat-sitting-near-building-wall.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  
                  alt="carousel1"
                />
              </div>
              <div class="carousel-item">
                <img
                  className="carousel-img"
                  src="https://images.pexels.com/photos/12465540/pexels-photo-12465540.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  alt="carousel2"
                />
              </div>
              <div class="carousel-item">
                <img
                  className="carousel-img"
                  src="https://images.pexels.com/photos/18445718/pexels-photo-18445718/free-photo-of-children-on-carousel-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load"
                  alt="carousel3"
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
      
          <div className="d-flex px-3 mt-4">
          <div>
            <h5>New Offers</h5>
          </div>
          <div className="ms-auto">
            <i class="fa-solid iconclr fs-6 bg-white fa-chevron-right rounded-1 p-2"></i>
          </div>
        </div>

      </div>
        </>
    );
}
export default Offers;