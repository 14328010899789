import React from "react";
import "../src/Terms.css";

const RefundPolicy=()=>{
    return(
        <>
        <div className="container py-5">
      <h2 className="text-center mb-4 aboutcontent">Refund Policy</h2>
      <h5 className="aboutcontent pb-3">
        At VIPCards, customer satisfaction is our priority. This Refund Policy outlines the conditions under which refunds are processed.
      </h5>

      <h5>1. Eligibility for Refunds</h5>
      <p>
        Refunds may be issued under the following circumstances:
      </p>
      <ul>
        <li>You encounter a technical issue that prevents the use of our service, and our support team cannot resolve it within 7 business days.</li>
        <li>You were charged incorrectly or for services you did not use.</li>
        <li>The product or service delivered does not match the description provided at the time of purchase.</li>
      </ul>

      <h5>2. Non-Refundable Services</h5>
      <p>
        Refunds are not applicable under the following conditions:
      </p>
      <ul>
        <li>Change of mind after purchasing or subscribing to our services.</li>
        <li>Failure to use the service within the subscription period.</li>
        <li>Issues caused by user error, such as providing incorrect or incomplete details.</li>
      </ul>

      <h5>3. Refund Request Process</h5>
      <p>To request a refund, please follow these steps:</p>
      <ol>
        <li>Email our support team at <a href="mailto:refunds@vipcards.com" className="text-decoration-none">refunds@vipcards.com</a> with the subject line "Refund Request".</li>
        <li>Include your order ID, payment receipt, and a detailed explanation of the issue.</li>
        <li>Our team will review your request within 5-7 business days and notify you of the outcome.</li>
      </ol>

      <h5>4. Refund Timeline</h5>
      <p>
        If your refund request is approved, the amount will be credited back to your original payment method within 7-10 business days. Processing times may vary depending on your bank or payment provider.
      </p>

      <h5>5. Contact Us</h5>
      <p>
        For any questions about our Refund Policy, please contact us at <a href="mailto:help@vipcards.com" className="text-decoration-none">help@vipcards.com</a>.
      </p>
    </div>
        </>
    )
}

export default RefundPolicy;