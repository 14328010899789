import React from "react";
import "../Home.css";
import "./Wcard.css";
import "./Mission.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Faq=()=>{
    return(
        <>
        <div className="container-fluid pt-5 mt-5 overflow-hidden p-0">
            <div className="patternbg">
                <div className="patterncontent">
                <h1 className="text-center text-white fw-bold"><i class="fa-solid fa-comments"></i></h1>
                <h2 className="text-center fw-bold text-white">What are you looking for?</h2>
                <p className="text-white text-center">Find answers and solutions to common IT issues. If you cant find <br/> an answer, contact us and we will be happy to help.</p>
                <div className="row">
                    <div className="col-8 mx-auto">
                        <input type="text" className="bg-white py-3 px-2 border-0 rounded-2 w-100" placeholder="search"/>
                    </div>
                </div>

                <p className="text-white text-center py-3">Please call our office at 712-819-5555 or email us with your question</p>
                </div>
            </div>

            <div className="row">
                <h2 className="aboutcontent text-center fw-bold py-4">Top ten most popular FAQs</h2>
            </div>
           
            <div className="row justify-content-center">
            <div className="col-12 col-md-4 mt-5">
                <NavLink to="/" className=" pb-0 text-decoration-none">
                    <h4 className="aboutcontent fw-bold ">VIPCARDS</h4>
        </NavLink>
        <p className="opacity-75 aboutcontent">NTSVIPCARDS.IN TEC-SOLVE PRIVATE LIMITED
        FLNO 13 NEELKANTH APT PLN, 21 SNO 46/3B WADGAONSHERI, Dukirkline, Pune City, Pune- 411014, Maharashtra</p>
                </div>
                
                <div className="col-5 col-md-3 mt-5">
                    <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">Company</li>
                        <li  >
                            <Link to="/about" className="text-decoration-none opacity-75 aboutcontent">About</Link>
                        </li>
                        <li  >
                            <Link to="/team" className="text-decoration-none opacity-75 aboutcontent">Leadership Team</Link>
                        </li>
                        <li  >
                            <Link to="/mission" className="text-decoration-none opacity-75 aboutcontent">IT Blogs</Link>
                        </li>
                       
                        <li  >
                            <Link to="/careers" className="text-decoration-none opacity-75 aboutcontent">Careers</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-5 col-md-3  mt-5">
                <ul className="list-unstyled">
                        <li className="aboutcontent fw-bold">IT Services</li>                    
                        <li  >
                            <Link to="/faqs" className="text-decoration-none opacity-75 aboutcontent">Help & FAQ</Link>
                        </li>
                        {/* <li  >
                            <Link to="/contactus" className="text-decoration-none opacity-75 aboutcontent">Contact Us</Link>
                        </li> */}
                       
                        <li  >
                            <Link to="/terms" className="text-decoration-none opacity-75 aboutcontent">Terms & Conditions</Link>
                        </li>
                        <li  >
                            <Link to="/privacypolicy" className="text-decoration-none opacity-75 aboutcontent">Privacy Policy </Link>
                        </li>
                        <li  >
                            <Link to="/refundpolicy" className="text-decoration-none opacity-75 aboutcontent">Refund Policy</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr className="my-2"/>
        <div className="row text-center pb-3">
            <div className="col-12">Copyright © 2018 NanoSoft. Designed and Developed by LineThemes Only on Envato Market.</div>
        </div>
        </div>
        </>
    )
    
}

export default Faq;